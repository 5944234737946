var isMobile = mobileAndTabletcheck();
var scrollTop = 0,
	mobileMenu = $(".mobile-menu"),
	wrapper = $(".wrapper");

function keepSticky(){
	var top = $(".sticky-nav-container").offset().top;
	if(scrollTop >= top){
		$(".sticky-nav").addClass('fixed');
	}else{
		$(".sticky-nav").removeClass('fixed');
	}
}

function aboutNavChange(){
	var array = [];
	$("section").each(function(){
		if(isScrolledIntoView(this, 'top')){
			array.push($(this).find('.anchor').attr('id'));
		}
	})
	$(".about-nav a[href='#" + array[0] + "']").parent().addClass('active').siblings().removeClass('active');

	// var observer = new IntersectionObserver(onEntry, {threshold: [0]});
    // $.each($('section'), function(i, v){
	//     observer.observe(this);
	// })
}

// function onEntry(entry) {
// 	entry.forEach((change) => {
// 		if(change.isIntersecting) {
// 			change.target.classList.add('visible');
// 		}else{
// 			change.target.classList.remove('visible');
// 		}
// 	});
// }

function homepageSliderResize(){
	$("#homepage-slider").height($(window).width() * 780/1920);
}

function mapInit(){
	var map;
	var geocoder = new google.maps.Geocoder();
	var center;
	map = new google.maps.Map(document.getElementById('googlemap'), {
		center: {lat: 22.365, lng: 114.133},
		zoom: 18,
		styles: [

		]
	})
	geocoder.geocode({
		'address': "葵涌葵昌路8號萬泰中心"
	}, function(results, status) {
		if (status == google.maps.GeocoderStatus.OK) {
			var pos = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng())
			var image;
			var marker = new google.maps.Marker({
				position: pos,
				map: map,
				icon: image!=null?image:null
			});
			map.setCenter(pos);
		}
	});
}

function backtopHandle(){
	if(scrollTop >= 600){
		$(".backto-top").addClass("show");
	}else{
		$(".backto-top").removeClass("show");
	}
}

$(function(){
	// if(isMobile) $('body').addClass('isMobile');

	var homepageSwiper = new Swiper('#homepage-slider', {
        pagination: {
        	el: '#homepage-slider .swiper-pagination',
        	clickable: true,
		},
        effect: 'fade',
        autoplay: {
			delay: 3000,
		},
    });

    // var eventSummarySwiper = new Swiper('#event-summary-slider', {
    // 	slidesPerView: 'auto',
    //     spaceBetween: 22,
    //     freeMode: true
    // });

    if($("#aboutus").length){
    	var centreTabSwiper = new Swiper('.centre-tab-swiper', {
	    	allowTouchMove: false,
	    	autoHeight: true,
			//on: {
			// 	slideChange: function () {
			// 		console.log(this.activeIndex);
			// 	}
			// },
	    });

	    $(".centre-nav a").click(function(e){
	    	e.preventDefault();
	    	centreTabSwiper.slideTo($(this).parent().index());
	    	$(this).parent().addClass('active').siblings().removeClass('active');
	    })

	    var mebTabSwiper = new Swiper('.meb-tab-swiper', {
	    	allowTouchMove: false,
	    	autoHeight: true,
			//on: {
			// 	slideChange: function () {
			// 		console.log(this.activeIndex);
			// 	}
			// },
	    });
	    $(".meb-tab a").click(function(e){
	    	e.preventDefault();
	    	mebTabSwiper.slideTo($(this).parent().index());
	    	$(this).parent().addClass('active').siblings().removeClass('active');
	    })

	    $(".members-blk .trigger").click(function(){
	    	var memberBlk = $(this).closest('.members-blk');
	    	memberBlk.toggleClass('opened').find('.detail').toggle();
	    	mebTabSwiper.update();
	    	centreTabSwiper.update();
	    })

	    $(".read-more-member").click(function(){
	    	$(this).parent().hide();
	    	$(".more-member-here").show();
	    	mebTabSwiper.update();
	    })

		$(window).on('scroll', aboutNavChange);
	}

	// hide greater than 10
	$(".research-team-meb .col-md:gt(9)").addClass('more-member-here');
	$(".research-team-meb .col-md:nth-child(5n)").after('<div class="d-none d-md-flex col-md-12"></div>');

	$(".about-nav a").click(function(e){
    	e.preventDefault();
    	$("html, body").animate({ scrollTop: $($(this).attr('href')).offset().top + 5 });
    })

	if($("#contactus").length || $("#membership").length){
		var formSwiper = new Swiper('.form-swiper', {
	    	allowTouchMove: false,
	    	autoHeight: true,
	    });
	    // formSwiper.slideTo(1);
	}

	var yearDropdown = $('.month-selector .dropdown.year'),
		monthDropdown = $('.month-selector .dropdown.month');

	if($('.item[selected]', yearDropdown).length){
		yearDropdown.dropdown('set selected', $('.item[selected]', yearDropdown).data('value'));
	}

	yearDropdown.dropdown({
		onChange: function(value, text, $choice){
			// console.log($choice);
			if($choice.attr('month')){
				var list = $choice.attr('month').split('|');
				monthDropdown.dropdown('clear');
				$('.menu .item:not(:first)', monthDropdown).remove();
				$.each(list, function(index, value){
					// console.log(value);
					$('.menu', monthDropdown).append('<div class="item" data-value="'+value+'">'+value+'</div>');
				})
				monthDropdown.dropdown('show');
			}else{
				var href = $(".month-selector").attr("href");
				window.location.href = href;
			}
		}
	})

	if($('.item[selected]', monthDropdown).length){
		monthDropdown.dropdown('set selected', $('.item[selected]', monthDropdown).data('value'));
	}
	
	monthDropdown.dropdown({
		onChange: function(value, text, $choice){
			if($choice){
				var href = $(".month-selector").attr("href");
				var year = yearDropdown.dropdown('get value');
				var month = monthDropdown.dropdown('get value');
				if(!isNaN(month)){
					window.location.href = href + '/' + year + '-' + month;
				}else{
					window.location.href = href + '/' + year;
				}
			}
		}, 
		onHide: function(){
			if(!monthDropdown.dropdown('get value') && yearDropdown.dropdown('get value')){
				monthDropdown.dropdown('set selected', 'all');
			}
		}
	});

	// $(".month-selector .year").change(function(){
	// 	var optionSelected = $("option:selected", this);
	// 	var list = optionSelected.attr('month').split('|');
	// 	$('.month-selector .month option:not(:first)').remove();
	// 	$.each(list, function(index, value){
	// 		$('.month-selector .month').append("<option value='" + value + "'>" + value + "</option>")
	// 	})
	// })

	// $(".month-selector .month").change(function(){
	// 	var href = $(".month-selector").attr("href");
	// 	var year = $(".month-selector .year").val();
	// 	var month = $(".month-selector .month").val();
	// 	if(!isNaN(month)){
	// 		window.location.href = href + '/' + year + '-' + month;
	// 	}else{
	// 		window.location.href = href + '/' + month;
	// 	}
	// })

	// $(".custom-form-style input, .custom-form-style textarea").on({
	// 	focus: function(){
	// 		$(this).addClass('active');
	// 	},
	// 	blur: function(){
	// 		if(!$(this).val()) $(this).removeClass('active');
	// 	}
	// }).each(function(){
	// 	if($(this).val()) $(this).addClass('active');
	// })
	$('.download.dropdown').dropdown({
	    action: 'hide'
	});

	if($("#cms-gallery").length){
		$( '#cms-gallery' ).sliderPro({
			width: 960,
			height: 600,
			// autoHeight: true,
			fade: true,
			arrows: true,
			buttons: false,
			fullScreen: true,
			// shuffle: true,
			waitForLayers: true,
			thumbnailArrows: true,
			autoplay: false
		});
	}
	

	$(".backto-top").click(function(e){
		e.preventDefault();
		$("html, body").animate({ scrollTop: 0 });
	})

	$(".popup-info").popup();

    if($(".sticky-nav-container").length) $(window).on('scroll', keepSticky);
    
    if($("#homepage-slider").length) $(window).on('resize', homepageSliderResize);

    var filterResearchForm = $("#filterResearch");
    $(".ajaxtype").click(function(e){
    	e.preventDefault();
    	var type = $(this).data("ajaxtype");
    	if(type){
    		$(type, filterResearchForm).prop("checked", true);
    	}else{
    		$("[name='Filter[type]']", filterResearchForm).prop("checked", false);
    	}
    	$(this).parent().addClass("active").siblings().removeClass("active");
    	$("#currentPage").val(1);
    	filterResearchForm.request();
    })
    $(".ajaxcategory").click(function(e){
    	e.preventDefault();
    	var category = $(this).data("ajaxcategory");
    	if($(this).parent(".active").length){
    		$(category, filterResearchForm).prop("checked", false);
    	}else{
    		$(category, filterResearchForm).prop("checked", true);
    	}
    	$(this).parent().toggleClass("active");
    	$("#currentPage").val(1);
    	filterResearchForm.request();
    })
    $("#research-paginate, #video-paginate").on('click', '.ajaxpagination', function(e){
    	e.preventDefault();
    	var page = $(this).data("page");
    	if(page){
    		$("#currentPage").val(page);
    	}
    	filterResearchForm.request();
    })

    if($(".accordion-container").length){
    	$(".accordion-container .trigger a").click(function(e){
    		e.preventDefault();
    		var container = $(this).closest(".accordion-container");
    		container.toggleClass('active');
    		$(".detail", container).slideToggle();
    	})
    }

    $(window).on('scroll', backtopHandle);

    $("#banner .imgsrc").parent().css(
    	'background-image', 'url(' + $("#banner .imgsrc").attr("src") + ')'
    ).end().hide();

    $(".bg-blend .bg-blend-img").each(function(){
    	$(this).parent().css(
	    	'background-image', 'url(' + $(this).attr("src") + ')'
	    ).end().hide();
    })

    $(".menu-trigger").click(function(e){
    	e.preventDefault();
    	if(mobileMenu.is(":visible")){
    		mobileMenu.removeClass('open');
    		setTimeout(function(){
    			mobileMenu.hide();
    		}, 500);
    		wrapper.removeClass('move');
    		$("html").removeClass('noscrolling');
    	}else{
    		mobileMenu.show();
    		setTimeout(function(){
    			mobileMenu.addClass('open');
    		});
    		wrapper.addClass('move');
    		$("html").addClass('noscrolling');
    	}
    })
    $(document).on('click touchend', function(e){
		if($(e.target).closest('.wrapper').length && parseInt(mobileMenu.css('right'))==0){
			mobileMenu.removeClass('open');
    		setTimeout(function(){
    			mobileMenu.hide();
    		}, 500);
    		wrapper.removeClass('move');
    		$("html").removeClass('noscrolling');
    		return false;
		}
	})

	$(".open-search").click(function(e){
		e.preventDefault();
		$(".search-nav").show().find(".form-control").focus();
		$("html").addClass('noscrolling');
	})
	$(".search-nav .close-btn").click(function(e){
		e.preventDefault();
		$(".search-nav").hide();
		$("html").removeClass('noscrolling');
	})

	$(".cms-content iframe").wrap("<div class='iframe-container'></div>");
    
	$(window).resize(function(){
		$("#banner").height(520/1780 * $(window).width());
		// $("[widthresize]").width($(".sticky-nav-container .container").outerWidth());
	}).trigger('resize');

	$(window).scroll(function(){
		scrollTop = $(window).scrollTop();
	}).trigger('scroll');
	
})
$(window).load(function() {
	$(".preload").removeClass("preload");
	$(".anim").addClass("ready");
})